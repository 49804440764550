import React, { useEffect, useState } from 'react'
import { x } from '@xstyled/styled-components'
import axios from 'axios'
import qs from 'qs'
import { urlB64ToUint8Array } from '../ServiceWorker'
import StyledLink from './ui/Link'
import Popup from 'ion-popup'
import iolBlocks from '../../../assets/images/general/push-notifications.svg'

const DefaultTemplate = ({ componentText, subscribeConcept }) => (
  <x.button text='small' bg={{ _: 'white', hover: 'red' }} color={{ _: 'red', hover: 'white' }} borderWidth='1px' borderStyle='solid' borderColor='red' textAlign='center'  borderRadius='100px' textTransform='uppercase' mb={20} px={20} py={8} onClick={() => subscribeConcept()}>{componentText}</x.button>
)

const FollowConcept = ({ topic, followText, unFollowText, template, sessionId, visitorId, section }) => {
  const Template = template || DefaultTemplate
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [helpPopupVisible, setHelpPopupVisible] = useState(false)
  const [subscription, setSubscription] = useState(false)
  const [registration, setRegistration] = useState(false)
  const clickSource = 'Follow Concept'

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration()
        .then(reg => {
          console.log('*** FollowConcept: registration', reg)
          setRegistration(reg)
        })
    }
  }, [])

  useEffect(() => {
    console.log('*** FollowConcept: registration', registration)
    if (registration && registration.pushManager) {
      registration.pushManager.getSubscription()
        .then(sub => {
          console.log('*** FollowConcept: subscription', sub)
          setSubscription(sub)
        })
    }
  }, [registration])

  useEffect(() => {
    console.log('FollowConcept setIsSubscribed', topic.name, subscription, topic.topic)
    async function setSubscribed () {
      const isSubscribed = await isSubscribedToConcept(topic.topic, subscription)
      setIsSubscribed(isSubscribed)
    }
    setSubscribed()
  }, [subscription, topic.topic])

  function isSubscribedToConcept (uuid, subscription) {
    if (!subscription) {
      console.log('*** isSubscribedToConcept: User is not subscribed to any push notifications')
      return Promise.resolve(false)
    }
    const data = qs.stringify({
      titleKey: process.env.RAZZLE_TITLE_KEY,
      endpoint: (subscription && subscription.endpoint) || '' 
    })
    return axios.post(process.env.RAZZLE_PUSH_SERVICE + '/topics', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(res => {
        const subscribed = res.data.filter(topic => topic.topic === uuid)
        console.log('*** subscriptions response', res.status, res.data, 'subscribed:', (subscribed.length > 0))
        return (subscribed.length > 0)
      })
      .catch(error => {
        console.log('*** isSubscribedToConcept error:', error.message)
        return false
      })
  }

  function subscribeConcept (uuid, subscription) {
    const data = {
      topic: uuid,
      titleKey: process.env.RAZZLE_TITLE_KEY,
      subscription: JSON.stringify(subscription)
    }
    console.log('*** FollowConcept:', 'Subscribing', data)
    console.log('Calling', isSubscribed ? 'unsubscribe' : 'subscribe', topic.name)
    axios.post(process.env.RAZZLE_PUSH_SERVICE + (isSubscribed ? '/unsubscribe' : '/subscribe'), qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then(res => setIsSubscribed(!isSubscribed))
    .catch(error => {
      console.log(isSubscribed ? 'Unsubscribe' : 'Subscribe', 'Error:', error.message)
      return false
    })
  }

  function registerAndSubscribeConcept (uuid) {
    console.log('*** FollowConcept:', 'Registering and subscribing')
    if (registration && registration.pushManager) {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(process.env.RAZZLE_SERVERKEY)
      })
        .then(function (subscription) {
          console.log('User IS now subscribed.')
          setSubscription(subscription)
          return subscribeConcept(uuid, subscription)
        })
        .catch(function (err) {
          console.log('Failed to subscribe the user: ', err)
          setHelpPopupVisible(true)
          setSubscription(false)
        })
    }
  }

  const canPush = !!(process.env.RAZZLE_ENABLE_PUSHNOTIFICATIONS && typeof window !== 'undefined' && typeof window.navigator !== 'undefined' && subscription)
  const isFollowing = canPush && isSubscribed
  console.log('*** FollowConcept:', {canPush, isFollowing, subscription})

  if (canPush) {
    return <Template componentText={isFollowing ? unFollowText : followText} subscribeConcept={() => subscribeConcept(topic.topic, subscription)} />
  } else {
    if (registration && registration.pushManager) {
      return (
        <>
          {helpPopupVisible &&
            <Popup visible>
              <x.div display='grid' gridTemplateColumns='56px auto' gap={20}>
                <x.div>
                  <x.img src={iolBlocks} alt='IOL' w={56} />
                </x.div>
                <x.div color='grey'>
                  <x.p text='xlarge' fontWeight='medium'>Small problem..</x.p>
                  <x.p mt={5}>You have previously chosen to block notifications.</x.p>
                  <x.p mb={15}>To follow stories requires you to enable notifications in your browser settings.</x.p>
                  <x.div display='flex' justifyContent='flex-end'>
                    <StyledLink onClick={() => setHelpPopupVisible(false)} to={'/help'} visitorId={visitorId} sessionId={sessionId} section={section} source={clickSource}><x.p mt={3} mr={15}>Help</x.p></StyledLink>
                    <x.button onClick={() => setHelpPopupVisible(false)} bg='#2E5FDC' color='white' p='6px 20px' borderRadius={4} ml={5}>OK</x.button>
                  </x.div>
                </x.div>
              </x.div>
            </Popup>}
          <Template componentText={followText} subscribeConcept={() => registerAndSubscribeConcept(topic.topic)} />
        </>
      )
    } else {
      return null
    }
  }
}

export default FollowConcept
