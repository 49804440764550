import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { x } from '@xstyled/styled-components'

import Articles from '../components/Articles'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import { sectionPageView, setPageType } from '../../store/app'
import { getVisitorId, getSessionId } from '../../store/article'
import WingBanners from '../components/WingBanners'
import Ad from '../components/Ad'
import {FORMATS, MAPPINGS} from '../components/AdFormats'
import NoImage from '../../../assets/images/general/no-image.png'

import WeatherWidget from '../components/WeatherWidget'
import ArticleDeck from '../../templates/ArticleDeck'
import ThreeArticleDeck from '../../templates/ThreeArticleDeck'
import SixArticleDeck from '../../templates/SixArticleDeck'

import { WeatherPageSkeleton as Skeleton } from '../components/Skeletons'
import BasicTemplate from '../../templates/Default'
import NotFound from './NotFound'
import { getTeaser } from '../../lib/articleUtils'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const HP = [
  [[1024, 0], [[300, 600], 'fluid']],
  [[640, 0], [[300, 600], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const mpuRHS = [
  [[1024, 0], [[300, 250], 'fluid']],
  [[640, 0], [[300, 250], [336, 280], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const DefaultTemplate = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath }) => (
  <ul>
    {articles.map(article => (
      <li key={article.uuid}>{getTeaser(article)}</li>
    ))}
  </ul>
)

const WeatherPage = (props) => {
  const adPath = `${props.adPath || (props.location.pathname + '/homepage')}`
  setPageType('section')
  useEffect(
    () => {
      if (('/' + props.section) === props.location.pathname) {
         props.sectionPageView(props.location.pathname, props.section, props.visitorId, props.sessionId, props.app.firstRender)
      }
    },
    [props.section]
  )
  return (
    <>
      <Helmet titleTemplate={'%s'}
        title={props.title} >
        <meta property='og:title' content={props.title} />
        <meta itemProp='headline' content={props.title} />
        <meta name='description' content={props.description} />
        <meta itemProp='description' content={props.description} />
        <meta property='og:description' content={props.description} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:site' content={props.twitterName} />
        <meta name='twitter:creator' content={props.twitterName} />
        <meta name='twitter:title' content={props.title} />
        <meta name='twitter:description' content={props.description} />
      </Helmet>
      <x.main px={{ sm: '20px', wAdD: props.app.wallpaperActive ? '0px' : '200px' }} mt={{ sm: '96px', lg: props.app.wallpaperActive ? '0px' : '173px' }} w='100%' maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} mx='auto'>
        <Ad isConnected={props.isConnected} adPath={props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
        <WingBanners isConnected={props.isConnected} adPath={adPath} location={props.location} mappingLeft={Wing} mappingRight={Wing} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
        <Articles
          {...props} noDiv isConnected={true} loading={Skeleton} section={props.section} count={17} pageSize={16} withMore moreButtonColour='btn-red' noImage={NoImage} notFound={NotFound} component={({ articles, section, noImage, moreButton, isConnected, withReadCount }) => (
            <>
              <x.div my={20} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Ad isConnected={props.isConnected} adPath={adPath} collapseEmptyDiv slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} className='advert' mapping={MAPPINGS['Leaderboard']} {...FORMATS.leaderboard} />
              </x.div>
              <WeatherWidget />
              <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
                <SixArticleDeck articles={articles.slice(0, 6)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={HP} visitorId={props.visitorId} sessionId={props.sessionId} />
                <ArticleDeck articles={articles.slice(6, 10)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
                <ThreeArticleDeck articles={articles.slice(10, 13)} section={props.section} noImage={noImage} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
                <ArticleDeck articles={articles.slice(13)} section={props.section} noImage={noImage} moreButton={moreButton} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={mpuRHS} visitorId={props.visitorId} sessionId={props.sessionId} />
              </x.div>
            </>
          )}
        />
        <x.div mt={20} mb={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Ad isConnected={props.isConnected} adPath={adPath} collapseEmptyDiv slotId='Leaderboard-bot' targeting={{ Leaderboard: 'bot' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </x.div>
      </x.main>
    </>
    )
  }

WeatherPage.defaultProps = {
  component: BasicTemplate,
  section: 'news'
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticles, fetchMoreArticles, sectionPageView }, dispatch)

export default withRouter(connect((state) => ({ app: state.app, articles: state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(WeatherPage))
