import React, { Fragment } from 'react'
import { getCanonical, getImageSize, getImage, getTeaser } from '../lib/articleUtils'
import { x } from '@xstyled/styled-components'
import { formatAgo } from '../lib/dateUtils'
import ImageOrNoImage from '../app/components/ui/Image'
import NoImage from '../../assets/images/general/no-image.png'
import StyledLink from '../app/components/ui/Link'
import Ad from '../app/components/Ad'
import { FORMATS } from '../app/components/AdFormats'

const Template = ({ articles, authorName, section, noImage, isConnected, sectionLabel, moreButton, relatedSections, withReadCount, adPath, mapping, visitorId, sessionId }) => {
  const clickSource = 'Article Four Row'
  return (
    <x.div display={{ sm: '', lg: 'grid' }} gridTemplateColumns={{ sm: '', lg: 'repeat(4, calc((100% - 120px) / 4))' }} gap={{ lg: '40' }}>
      {articles.map((article, index) => (
        <Fragment key={index}>
          {index > 0 && index % 16 === 0 &&
            <x.div key={index} maxWidth={1220} mx='auto' my={50} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' w='100%' gridColumn='span 4'>
              <Ad isConnected={isConnected} adPath={adPath} collapseEmptyDiv slotId='Leaderboard-middle' targeting={{ Leaderboard: 'middle' }} className='advert' mapping={mapping} {...FORMATS.leaderboard} />
            </x.div>}
          <x.article key={article.uuid} position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0', lastType: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0', lastType: '0' }} borderStyle='solid' borderColor='divider' mb={{ sm: '20px', lg: '0', last: '0' }}>
            <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' spaceY={{ sm: '0', lg: '10px' }} spaceX={{ sm: '10px', lg: '0' }} flexDirection={{ sm: 'row', lg: 'column-reverse' }} section={section} sessionId={sessionId} visitorId={visitorId} source={clickSource}>
              <x.div>
                <x.h5 text='h5' mt={0} mb={8}>{getTeaser(article)}</x.h5>
                <x.p text='meta' color='meta'>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
              </x.div>
              <x.div position='relative' display='flex' justifyContent='center' alignItems={{ sm: 'flex-start', lg: 'unset' }}>
                <ImageOrNoImage image={getImage(article)} w='auto' width={getImageSize('slider')} alt={article.headline} shape='16x9' display={{ sm: 'none', lg: 'block' }} visibility={{ sm: 'hidden', lg: 'visible' }} noImage={NoImage} />
                <ImageOrNoImage image={getImage(article)} maxWidth={50} width={getImageSize('slider')} alt={article.headline} shape='square' display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }} noImage={NoImage} />
              </x.div>
            </StyledLink>
          </x.article>
        </Fragment>
      ))}
      {moreButton}
    </x.div>
  )
}

const ArticleFourRow = (props) => {
  return (
    <Template {...props} />
  )
}

export default ArticleFourRow
