import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { x, useDown } from '@xstyled/styled-components'
import useHasMounted from '../../lib/useHasMounted'
import Helmet from 'react-helmet'

import Articles from '../components/Articles'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import { sectionPageView, setPageType } from '../../store/app'
import { getVisitorId, getSessionId } from '../../store/article'
import WingBanners from '../components/WingBanners'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import ArticleFourRow from '../../templates/ArticleFourRow'
import NotFound from './NotFound'
import NoImage from '../../../assets/images/general/no-image.png'
import StyledLink from '../components/ui/Link'
import ImageOrNoImage from '../components/ui/Image'
import { formatAgo } from '../../lib/dateUtils'
import { getCanonical, getImageSize, getImage, getTeaser } from '../../lib/articleUtils'
import FollowConcept from '../components/FollowConcept'
import { BasicPageSkeleton as Skeleton } from '../components/Skeletons'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const HP = [
  [[1024, 0], [[300, 600], 'fluid']],
  [[640, 0], [[300, 600], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const DefaultTemplate = ({ articles, section, sectionLabel, banner, noImage, isConnected, moreButton, withReadCount, adPath, sectionsLabels, visitorId, sessionId }) => {
  const clickSource = 'Trend Page'
  return (
    <>
      <x.div w='100%' maxWidth={1220} mx='auto' my={50} h={165} backgroundImage={banner ? `url(${banner})` : 'url(/images/standard.jpg)'} backgroundRepeat='no-repeat' backgroundPosition='50%' backgroundSize='cover' overflow='hidden' display='flex' justifyContent='center' alignItems='center' position='relative'>
        <x.div position='absolute' top='0' left='0' right='0' bottom='0' backgroundColor='rgba(0, 0, 0, 0.35);'>
        <x.div position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {sectionLabel && sectionLabel !== 'none' && <x.h1 text={{ sm: 'xlarge', lg: 'xxlarge' }} textTransform='uppercase' fontFamily='"PT Serif", serif' color='white' w='auto'>{sectionLabel}</x.h1>}
          <x.div mt={20}>
            {sectionsLabels && sectionsLabels[0] && sectionsLabels[0].uuid &&
              <FollowConcept
                topic={{ topic: sectionsLabels[0].uuid, type: 'trend', name: sectionsLabels[0].label }}
                followText='Follow Trend'
                unFollowText='Unfollow Trend'
                template={({ componentText, subscribeConcept }) => (
                  <x.button text='small' bg={{ _: 'transparent', hover: 'white' }} color={{ _: 'white', hover: 'red' }} borderWidth='1px' borderStyle='solid' borderColor='white' textAlign='center' borderRadius='100px' textTransform='uppercase' mb={20} px={20} py={8} onClick={() => subscribeConcept()}>{componentText}</x.button>
                )}
                visitorId={visitorId}
                sessionId={sessionId}
                section={section}
              />}
          </x.div>
        </x.div>
      </x.div>
      </x.div>
      <x.div w='100%' maxWidth={1220} mx='auto' display='grid' gridTemplateColumns={{ sm: '100%', lg: 'minmax(min-content, 560px) minmax(min-content, 282px) 300px' }} gap={{ lg: '40' }} mt={50} mb={30}>
        <x.div display='flex' flexDirection='column' position='relative' className='divider-right' p={{ sm: '0 0 20px 0', lg: '0' }} borderWidth={{ sm: '0 0 1px 0', lg: '0' }} borderStyle='solid' borderColor='divider'>
        <x.article>
          <StyledLink article={articles[0]} to={'/' + getCanonical(articles[0])} text='secondary' section={section} visitorId={visitorId} sessionId={sessionId} source={clickSource}>
            <ImageOrNoImage image={getImage(articles[0])} w='100%' mb={10} width={getImageSize('hero')} alt={getTeaser(articles[0])} shape='16x9' noImage={NoImage} />
            <x.h2 text='h1'>{getTeaser(articles[0])}</x.h2>
            <x.p text='meta' color='meta' my={8}>By {articles[0].author} | Published {formatAgo(articles[0].published, true)}</x.p>
            <x.p text='large'>{articles[0].abstract}</x.p>
          </StyledLink>
        </x.article>
      </x.div>
        <x.div position='relative' p={{ sm: '20px 0', lg: '0' }} className='divider-right' borderWidth='0'>
        {articles.slice(1, 3).map(article => (
          <x.article key={article.contentKey} pb={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' spaceX={10} section={section} visitorId={visitorId} sessionId={sessionId} source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(article)}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
              </x.div>
              <x.div>
                <ImageOrNoImage image={getImage(article)} w={{ sm: '50px', lg: '100%' }} mb={10} width={getImageSize('normal')} alt={getTeaser(article)} shape="square" noImage={NoImage} />
              </x.div>
            </StyledLink>
          </x.article>
        ))}
        {articles.slice(3, 6).map(article => (
          <x.article key={article.contentKey} py={20} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider'>
            <StyledLink article={article} to={'/' + getCanonical(article)} display='flex' spaceX={10} section={section} visitorId={visitorId} sessionId={sessionId} source={clickSource}>
              <x.div flex='1 1 0'>
                <x.h6 text='h6'>{getTeaser(article)}</x.h6>
                <x.p text='meta' color='meta' mt={8}>By {article.author} | Published {formatAgo(article.published, true)}</x.p>
              </x.div>
              <x.div display={{ sm: 'block', lg: 'none' }} visibility={{ sm: 'visible', lg: 'hidden' }}>
                <ImageOrNoImage image={getImage(article)} w={{ sm: '50px', lg: '100%' }} mb={10} width={getImageSize('normal')} alt={getTeaser(article)} shape="square" noImage={NoImage} />
              </x.div>
            </StyledLink>
          </x.article>
        ))}
      </x.div>
        <x.aside>
        <x.div textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column' p={{ sm: '20px', lg: '0' }}>
          <Ad isConnected={isConnected} adPath={adPath} slotId='MPU600-right' targeting={{ mpu600: 'right' }} mapping={HP} collapseEmptyDiv {...FORMATS.mpu600} />
        </x.div>
      </x.aside>
      </x.div>
      <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
        <ArticleFourRow articles={articles.slice(5)} section={section} noImage={noImage} moreButton={moreButton} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} visitorId={visitorId} sessionId={sessionId} />
      </x.div>
    </>
  )
}

const DefaultTrendPage = (props) => {
  const Template = props.template || DefaultTemplate
  const hasMounted = useHasMounted()
  const downMd = useDown('md')
  setPageType('section')
  useEffect(
    () => {
      if (('/' + props.section) === props.location.pathname) {
        props.sectionPageView(props.location.pathname, props.section, props.visitorId, props.sessionId, props.app.firstRender)
      }
    },
    [props.section]
  )
  return (
    <>
      <Helmet
titleTemplate="%s"
        title={props.title}>
        <meta property='og:title' content={props.title} />
        <meta itemProp='headline' content={props.title} />
        <meta name='description' content={props.description} />
        <meta itemProp='description' content={props.description} />
        <meta property='og:description' content={props.description} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:site' content={props.twitterName} />
        <meta name='twitter:creator' content={props.twitterName} />
        <meta name='twitter:title' content={props.title} />
        <meta name='twitter:description' content={props.description} />
      </Helmet>
      <x.main px={{ sm: '20px', wAdD: props.app.wallpaperActive ? '0px' : '200px' }} mt={{ sm: '96px', lg: props.app.wallpaperActive ? '0px' : '173px' }} w='100%' maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} mx='auto'>
        <Ad isConnected={props.isConnected} adPath={props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
        <WingBanners isConnected={props.isConnected} adPath={props.adPath} location={props.location} mappingLeft={Wing} mappingRight={Wing} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
        <Articles
          {...props} noDiv isConnected loading={Skeleton} section={props.section} sectionLabel={props.sectionLabel} sectionsLabels={props.sectionsLabels} count={25} pageSize={16} withMore moreButtonColour='btn-red' noImage={NoImage} notFound={NotFound} component={Template}
        />
        {hasMounted && !downMd &&
          <x.div my={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <Ad isConnected={props.isConnected} adPath={props.adPath} collapseEmptyDiv slotId='Leaderboard-bot' targeting={{ Leaderboard: 'bot' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
          </x.div>}
      </x.main>
    </>
  )
}

DefaultTrendPage.defaultProps = {
  component: DefaultTemplate,
  section: 'trends/plantbasedcooking'
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticles, fetchMoreArticles, sectionPageView }, dispatch)

export default withRouter(connect((state) => ({ app: state.app, articles: state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(DefaultTrendPage))
