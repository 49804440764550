import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'
import { x, useDown } from '@xstyled/styled-components'
import useHasMounted from '../../lib/useHasMounted'

import Articles from '../components/Articles'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import { sectionPageView, setPageType } from '../../store/app'
import { getVisitorId, getSessionId } from '../../store/article'
import WingBanners from '../components/WingBanners'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import ArticleFourRow from '../../templates/ArticleFourRow'
import { BasicPageSkeleton as Skeleton } from '../components/Skeletons'
import FollowConcept from '../components/FollowConcept'
import NotFound from './NotFound'
import NoImage from '../../../assets/images/general/no-image.png'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const DefaultTemplate = ({ articles, section, noImage, isConnected, sectionLabel, sectionsLabels, moreButton, withReadCount, adPath, visitorId, sessionId }) => (
  <>
    <Helmet titleTemplate="%s" title={(sectionLabel + ' | Latest News & Developments | IOL')}>
      <meta property='og:title' content={(sectionLabel + ' | Latest News & Developments | IOL')} />
      <meta itemProp='headline' content={(sectionLabel + ' | Latest News & Developments | IOL')} />
    </Helmet>
    <x.div w='100%' maxWidth={1220} mx='auto' my={50} borderWidth='0 0 1px 0' borderStyle='solid' borderColor='divider' display='flex' justifyContent='space-between'>
      <x.h1 text='h4' fontFamily='"PT Serif", serif' pb={20}>{sectionLabel}</x.h1>
      {sectionsLabels && sectionsLabels[0] && sectionsLabels[0].uuid &&
        <FollowConcept
topic={{ topic: sectionsLabels[0].uuid, type: 'tag', name: sectionsLabels[0].label }}
          followText="Follow Tag"
          unFollowText="Unfollow Tag"
          sessionId={sessionId}
          visitorId={visitorId}
          section={section}
        />}
    </x.div>
    <x.div maxWidth={1220} w='100%' mx='auto' mt={50}>
      <ArticleFourRow articles={articles} section={section} noImage={noImage} moreButton={moreButton} isConnected={isConnected} withReadCount={withReadCount} adPath={adPath} mapping={Leaderboard} visitorId={visitorId} sessionId={sessionId} />
    </x.div>
  </>
)

const TagPage = (props) => {
  const Template = props.template || DefaultTemplate
  const hasMounted = useHasMounted()
  const downMd = useDown('md')
  setPageType('section')
  useEffect(
    () => {
      if (('/' + props.section) === props.location.pathname) {
        props.sectionPageView(props.location.pathname, props.section, props.visitorId, props.sessionId, props.app.firstRender)
      }
    },
    [props.section]
  )
  return (
    <>
      <x.main px={{ sm: '20px', wAdD: props.app.wallpaperActive ? '0px' : '200px' }} mt={{ sm: '96px', lg: props.app.wallpaperActive ? '0px' : '173px' }} w='100%' maxWidth={{ sm: '1260px', wAdD: props.app.wallpaperActive ? '1220px' : '1660px' }} mx='auto'>
        <Ad isConnected={props.isConnected} adPath={props.adPath} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
        <WingBanners isConnected={props.isConnected} adPath={props.adPath} location={props.location} mappingLeft={Wing} mappingRight={Wing} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} />
        <Articles
          {...props} noDiv isConnected loading={Skeleton} section={props.section} count={24} pageSize={16} withMore moreButtonColour='btn-red' noImage={NoImage} notFound={NotFound} component={Template}
        />
        {hasMounted && !downMd &&
          <x.div mt={20} mb={50} mx={-20} textAlign='center' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <Ad isConnected={props.isConnected} adPath={props.adPath} collapseEmptyDiv slotId='Leaderboard-bot' targeting={{ Leaderboard: 'bot' }} className='advert' mapping={Leaderboard} {...FORMATS.leaderboard} />
          </x.div>}
      </x.main>
    </>
  )
}

TagPage.defaultProps = {
  component: DefaultTemplate,
  section: 'tags/local-government-elections'
}
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticles, fetchMoreArticles, sectionPageView }, dispatch)

export default withRouter(connect((state) => ({ app: state.app, articles: state.articles, visitorId: getVisitorId(state), sessionId: getSessionId(state) }), mapDispatchToProps)(TagPage))
